
import './_successAlert.scss';
import CircleCheck from "./../../assets/icons/circle-check.svg";

export const SuccesAlert = () => {
    return (
        <div className="success-alert" >
            <div className="alert-content">
                <img src={CircleCheck} alt="" />
                <p>Təşəbbüsünüz üçün təşəkkürlər! Əməkdaşımız sizinlə ən qısa zamanda əlaqə saxlayacaqdır.</p>
            </div>
        </div>
    )
}

export default SuccesAlert;
