import "./_productAdvantages.scss";
import Money from "./../../assets/icons/money.svg";
import Percent from "./../../assets/icons/percent.svg";
import Shield from "./../../assets/icons/shield.svg";

export const ProductAdvantages = () => {
  return (
    <div className="product-advantages-section">
      <div className="container">
        <div className="product-advantages-content">
          <h2 className="product-advantages-title">Məhsulun üstünlükləri</h2>
          <div className="product-advantages">
            <div className="product-advantage">
              <div className="img">
                <span className="warning-light-bg">
                  <img src={Money} alt="" />
                </span>
              </div>
              <div className="content">
                <h4 className="title">Dollar ilə gəlir imkanı</h4>
                <p className="text">Pulunuzu əmin əllərdə qoruyursunuz</p>
              </div>
            </div>
            <div className="product-advantage">
              <div className="img">
                <span className="danger-light-bg">
                  {" "}
                  <img src={Percent} alt="" />
                </span>
              </div>
              <div className="content">
                <h4 className="title">Yüksək gəlirlilik faizi</h4>
                <p className="text">
                  Müqavilə müddətinin sonunda əldə etdiyiniz 1% gəlir
                </p>
              </div>
            </div>
            <div className="product-advantage">
              <div className="img">
                <span className="success-light-bg">
                  <img src={Shield} alt="" />
                </span>
              </div>
              <div className="content">
                <h4 className="title">Həyat Sığortası</h4>
                <p className="text">
                  Bu məhsul ilə həyatınızı sığortalayırsınız
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductAdvantages;
