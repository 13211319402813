
import { useState, useEffect } from "react";
import "./_usefulCard.scss";
import CloseIcon from "./../../assets/icons/close-icon.svg";

const UsefulCard = () => {

    const [display, setDisplay] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0)

 
    const handleClick = (index) => {
        setActiveIndex(index)
    }

    const handleDisplayClick = () => {
        setDisplay(!display);
        localStorage.setItem('userAction', true);

    }
    useEffect(() => {
        const userAction = localStorage.getItem('userAction');
        userAction != null && setDisplay(false);
    }, [])





    return (

        <div className={`useful-card-section ${display ? '' : 'hidden'}`}  >
            <div className="container">
                <div className="useful-card" >
                    {activeIndex !== 0 && <div className="overlay" ></div>}
                    {activeIndex !== 0 && <img className="close-icon" onClick={handleDisplayClick} src={CloseIcon} alt="" />}
                    <div className="useful-card-content">
                        <div className="content-text" >
                            <h4 className="title" >Bu səhifə sizə kömək etdimi?</h4>
                            <p className="text" >Sizin üçün daha yaxşı olmağımız üçün kömək edin </p>
                        </div>
                        <div className="buttons">
                            <button className={`btn btn-outline-primary mr-1  ${activeIndex === 1 && 'active'}`} onClick={() => handleClick(1)} >Bəli</button>
                            <button className={`btn btn-outline-primary ${activeIndex === 2 && 'active'}`} onClick={() => handleClick(2)} >Xeyr</button>
                            {activeIndex !== 0 && <p>Rəyiniz üçün təşəkkür edirik!</p>}


                        </div>
                    </div>


                </div>
            </div>
        </div>


    )
}

export default UsefulCard;