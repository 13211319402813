import { useState } from "react";
import InputMask from "react-input-mask";
import SuccesAlert from "./SuccesAlert";
import loading from "./../../assets/icons/loading-icon.svg";
import "./_mainBoard.scss";

export const MainBoard = ({ smoothDiv, showAlert, setShowAlert }) => {
  const [infoData, setInfoData] = useState({
    name: "",
    number: "",
  });
  const { name, number } = infoData;

  const [valid, setValid] = useState({
    name: { isRequired: true },
    number: { isRequired: true },
  });

  // const [showAlert, setShowAlert] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  // const rexExp = /^[0-9\b]+$/;

  const handleChangeInput = (e) => {
    const targetName = e.target.name;
    setInfoData({
      ...infoData,
      [targetName]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setValid({
      name: { isRequired: name.trim() !== "" ? true : false },
      number: { isRequired: number !== "" ? true : false },
    });

    if (name.trim() !== "" && number !== "") {
      setShowLoading(true);

      const infoData = {
        id: 0,
        fullName: name,
        phone: number,
      };
      const headers = new Headers();

      headers.append("Content-Type", "application/json");
      headers.append("Accept", "application/json");
      // headers.append('Access-Control-Allow-Methods','POST,OPTIONS,GET');
      headers.append("Access-Control-Allow-Origin", "*");

      await fetch(
        "https://soft.pasha-life.az/_service_ghs_landingPage/api/CustomersInfo/AddCustomer",
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(infoData),
        }
      )
        .then((res) => {
          if (res.status === 200) {
            setShowAlert(true);
            setShowLoading(false);
            setInfoData({ name: "", number: "" });
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  };

  return (
    <div className="main-board-section" ref={smoothDiv}>
      <div className="container">
        <div className="main-board-content">
          <div className="get-info-part">
            <div className="form-header">
              <h1 className="title">DOLLAR ÜZRƏ YÜKSƏK FAİZ - 1% </h1>
              <p className="bottom-text">
                {" "}
                "Gəlirli Həyat Sığortası" müştərilərimiz üçün Kapital Bank ilə
                birgə hazırladığımız xüsusi yığım məhsuludur.{" "}
              </p>
            </div>
            <div className="form-content">
              {showAlert ? (
                <SuccesAlert />
              ) : (
                <form onSubmit={handleSubmit}>
                  <div
                    className={`form-group ${
                      !valid.name.isRequired && "invalid-form-group"
                    }`}
                  >
                    <label htmlFor="fullName">Ad, Soyad</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Qeyd edin"
                      name="name"
                      value={name}
                      onChange={handleChangeInput}
                    />
                    {!valid.name.isRequired && (
                      <p className="form-control-info-text">
                        Xananın doldurulması vacibdir!
                      </p>
                    )}
                  </div>
                  <div
                    className={`form-group ${
                      !valid.number.isRequired && "invalid-form-group"
                    }`}
                  >
                    <label htmlFor="fullName">Əlaqə nömrəsi</label>
                    <InputMask
                      type="text"
                      className="form-control"
                      mask="+\9\94 (99) 999 99 99"
                      placeholder="+994 (50) 000 00 00"
                      name="number"
                      value={number}
                      onChange={handleChangeInput}
                    />

                    {!valid.number.isRequired && (
                      <p className="form-control-info-text">
                        Xananın doldurulması vacibdir!
                      </p>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg d-block"
                  >
                    {" "}
                    {!showLoading ? (
                      "Məlumat alın"
                    ) : (
                      <span className="loading-icon">
                        <img src={loading} alt="" />
                      </span>
                    )}{" "}
                  </button>
                </form>
              )}
            </div>
          </div>
          <div className="board-background-part"></div>
        </div>
      </div>
    </div>
  );
};

export default MainBoard;
