import { useRef, useState } from "react";
import TagManager from "react-gtm-module";
import { YMInitializer as YM } from "react-yandex-metrika";
import AskedQuestions from "./components/AskedQuestions/AskedQuestions";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import MainBoard from "./components/MainBoard/MainBoard";
import ProductAdvantages from "./components/ProductAdvantages/ProductAdvantages";
import ProfitableAbout from "./components/ProfitableAbout/ProfitableAbout";
import UsefulCard from "./components/UsefulCard/UsefulCard";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import { AnalyticCodes } from "./globals";
import { useEffect } from "react";

ReactPixel.init(AnalyticCodes.PIXEL);
ReactPixel.pageView();

export const App = () => {
  const tagManagerArgs = {
    gtmId: AnalyticCodes.GTM,
  };
  TagManager.initialize(tagManagerArgs);

  const TRACKING_ID = AnalyticCodes.GA;
  ReactGA.initialize(TRACKING_ID);

  const [showAlert, setShowAlert] = useState(false);
  const smoothDiv = useRef();

  const smoothScroll = (target) => {
    const { top } = target.getBoundingClientRect();
    window.scrollTo({
      top: top + window.pageYOffset,
      behavior: "smooth",
    });
  };

  const showForm = () => {
    showAlert && setShowAlert(false);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  async function googleOptimizeInitialize() {
    if (window.dataLayer) {
      await window.dataLayer.push({ event: "optimize.activate" });
    }
  }

  useEffect(() => {
    googleOptimizeInitialize();
  }, []);

  return (
    <>
      <YM
        accounts={[AnalyticCodes.YANDEXMETRICA]}
        options={{
          webvisor: true,
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
        }}
        version="2"
      />
      <Header
        smoothScroll={smoothScroll}
        smoothDiv={smoothDiv}
        showForm={showForm}
      />
      <MainBoard
        smoothDiv={smoothDiv}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
      />
      <ProductAdvantages />
      <ProfitableAbout
        smoothScroll={smoothScroll}
        smoothDiv={smoothDiv}
        showForm={showForm}
      />
      <AskedQuestions />
      <UsefulCard />
      <Footer />
    </>
  );
};

export default App;
