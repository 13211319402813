import { Accordion } from 'react-bootstrap-accordion';
import data from "./../../accordion-data.json";

import "./_askedQuestions.scss";


const AskedQuestions = () => {
 

    return ( 
        <div className="asked-questions-section" >
            <div className="container">
                <div className="product-info-section">
                    <h2 className="title" >Bu məhsulu necə əldə edə bilərəm? </h2>
                    <div className="product-info-part" >
                        <div className="product-info">
                            <div>
                                <span className="number-circle" >1</span>
                            </div>
                            <div className="info-text" >
                                <p className="text" >Kapital Bankın istənilən filialına yaxınlaşırsınız</p>
                            </div>
                        </div>
                        <div className="product-info">
                            <div>
                                <span className="number-circle" >2</span>
                            </div>
                            <div className="info-text" >
                                <p className="text" >Gəlirli Həyat Sığortasına qoşulmaq istədiyinizi bildirirsiniz</p>
                            </div>
                        </div>
                        <div className="product-info">
                            <div>
                                <span className="number-circle" >3</span>
                            </div>
                            <div className="info-text" >
                                <p className="text" >Tək şəxsiyyət vəsiqəsi ilə bu məhsulu əldə edirsiniz</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="asked-questions-part">
                    <h2 className="title">Ən çox verilən suallar</h2>

                    {
                        data.map((d, index) => <Accordion key={d.title + index} title={d.title} > {d.description} </Accordion>)

                    }



                </div>
            </div>


        </div>
    )
}

export default AskedQuestions;
