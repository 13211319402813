import "./_header.scss";
import Logo from "./../../assets/icons/logo-header.svg";
import LogoMobile from "./../../assets/icons/logo-header-mobile.svg"

import { phone_icon } from "./../../helpers/Icons";

const Header = ({ smoothScroll, smoothDiv,showForm }) => {

    const handleClick = () =>{
        smoothScroll(smoothDiv.current);
        showForm();
    }
    return (
        <header>
            <div className="header" >
                <div className="container header-container">
                    <a href="/">
                        <img className="logo-web" src={Logo} alt="" />
                        <img  className="logo-mobile hidden" src={LogoMobile} alt="" />
                    </a>
                    <div className="header-info-buttons">
                        <button className="btn  mr-1 web-btn"> <span className="b-icon" >{phone_icon}</span>(012) 942</button>
                        <a href="tel:012942" className="btn  mr-1 hidden mobile-btn"> <span className="b-icon" >{phone_icon}</span> <span className="mobile-num">(012)</span> 942</a>

                        <button type="button" className="btn btn-primary" onClick={handleClick}>Məlumat alın</button>

                    </div>
                </div>
            </div>
        </header>

    )
}

export default Header;
