import './_footer.scss';
import Logo from "./../../assets/icons/logo-footer.svg";
import LogoMobile from "./../../assets/icons/logo-footer-mobile.png";

import Facebook from "./../../assets/icons/Facebook.svg";
import Instagram from "./../../assets/icons/Instagram.svg";
import Youtube from "./../../assets/icons/Youtube.svg";



export const Footer = () => {
    const currentDate = new Date();

    return (
        <div className="footer" >
            <div className="top-footer web">
                <div className="container">
                    <div className="info-part">
                        <a href="/" className="logo">
                            <img className="logo-web-footer" src={Logo} alt="" />
                            <img className="logo-mobile-footer hidden" src={LogoMobile} alt="" />

                        </a>
                        <div className="info-contents">
                            <div className="info-content" >
                                <h4 className="title">(+994 12) 942</h4>
                                <p className="text">Əlaqə mərkəzi</p>
                            </div>
                            <div className="info-content" >
                                <h4 className="title">M.Useynov küç., 61, AZ1003</h4>
                                <p className="text">Ünvan</p>
                            </div>
                            <div className="info-content" >
                                <h4 className="title">office@pasha-life.az</h4>
                                <p className="text">E-mail</p>
                            </div>
                        </div>

                    </div>
                    <div className="social-part">
                        <div className="social-bar">
                            <a href="https://m.facebook.com/pashalifeinsurance/?ref=bookmarks" className="socail-icon" target="blank">
                                <img src={Facebook} alt="" />
                            </a>
                            <a href="https://instagram.com/pashalifeinsurance?utm_medium=copy_link" className="socail-icon" target="blank">
                                <img src={Instagram} alt="" />
                            </a>
                            <a href="https://www.youtube.com/channel/UCefcS2bG_DkaFUUi42MBtiA" className="socail-icon" target="blank">
                                <img src={Youtube} alt="" />
                            </a>
                        </div>
                    </div>

                </div>
            </div>

            <div className="top-footer tablet">
                <div className="container">
                    <div className="top-footer-contents">
                        <div className="info-part">
                            <a href="/" className="logo">
                                <img className="logo-web-footer" src={Logo} alt="" />
                                <img className="logo-mobile-footer hidden" src={LogoMobile} alt="" />

                            </a>
                            <div className="info-contents">
                                <div className="info-content" >
                                    <h4 className="title">(+994 12) 942</h4>
                                    <p className="text">Fərdi və Korporativ müştərilər</p>
                                </div>
                                <div className="info-content" >
                                    <h4 className="title">M.Useynov küç., 61, AZ1003</h4>
                                    <p className="text">Ünvan</p>
                                </div>
                                <div className="info-content" >
                                    <h4 className="title">office@pasha-life.az</h4>
                                    <p className="text">E-mail</p>
                                </div>
                            </div>
                        </div>

                        <div className="social-bar">
                            <a href="https://m.facebook.com/pashalifeinsurance/?ref=bookmarks" className="socail-icon" target="blank">
                                <img src={Facebook} alt="" />
                            </a>
                            <a href="https://instagram.com/pashalifeinsurance?utm_medium=copy_link" className="socail-icon" target="blank">
                                <img src={Instagram} alt="" />
                            </a>
                            <a href="https://www.youtube.com/channel/UCefcS2bG_DkaFUUi42MBtiA" className="socail-icon" target="blank">
                                <img src={Youtube} alt="" />
                            </a>
                        </div>
                    </div>


                </div>
            </div>
            <div className="divider"></div>
            <div className="bottom-footer">
                <div className="container">
                    <div className="info-contents">
                        <p>© {currentDate.getFullYear()},  PAŞA Həyat Sığorta " ASC</p>
                        <a href="https://www.pasha-life.az/home?lang=az">www.pasha-life.az</a>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Footer;
