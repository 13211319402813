import "./_profitableAbout.scss";
import { arrow_right } from "../../helpers/Icons";
// import diagramImg from "./../..//assets/img/diagram.png"

const ProfitableAbout = ({ smoothScroll, smoothDiv, showForm }) => {
  const handleClick = () => {
    smoothScroll(smoothDiv.current);
    showForm();
  };
  return (
    <div className="profitable-about-section">
      <div className="container">
        <div className="profitable-about-box">
          <div className="profitable-about-content">
            <h2 className="profitable-about-title">
              Gəlirli Həyat Siğortası <br /> məhsulu haqqında
            </h2>
            <ul className="invoice-list">
              <li className="invoice">
                <h5 className="invoice-title">İllik faiz dərəcəsi: </h5>
                <p className="invoice-text">1%</p>
              </li>
              <li className="invoice">
                <h5 className="invoice-title"> Müqavilə müddəti: </h5>
                <p className="invoice-text">1 il</p>
              </li>
              <li className=" web invoice">
                <h5 className="invoice-title">Faizlərin ödənilməsi: </h5>
                <p className="invoice-text">Müddətin sonunda</p>
              </li>
            </ul>
            <ul className="invoice-list">
              <li className="invoice">
                <h5 className="invoice-title">Minimal məbləğ:</h5>
                <p className="invoice-text">100 $</p>
              </li>
              <li className="invoice">
                <h5 className="invoice-title">Maksimal məbləğ:</h5>
                <p className="invoice-text">10 000 000 $</p>
              </li>
              <li className="web  invoice">
                <h5 className="invoice-title">Hardan əldə edərəm:</h5>
                <p className="invoice-text">Kapital Bank filialarından</p>
              </li>
            </ul>
            <ul className="mobile invoice-list ">
              <li className="invoice">
                <h5 className="invoice-title">Faizlərin ödənilməsi: </h5>
                <p className="invoice-text">Müddətin sonunda</p>
              </li>
              <div className="gap"></div>
              <li className="invoice">
                <h5 className="invoice-title">Hardan əldə edərəm:</h5>
                <p className="invoice-text">Kapital Bank filialarından</p>
              </li>
            </ul>

            <button
              className="btn btn-lg btn-primary"
              type="button"
              onClick={handleClick}
            >
              Məlumat alın <span className="b-icon">{arrow_right}</span>{" "}
            </button>
          </div>

          <div className="profitable-about-bg"></div>
        </div>
      </div>
    </div>
  );
};

export default ProfitableAbout;
